import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import RigthBanner from "../templates/rigthBanner";

function connectString(string) {
  const arr = string.toLowerCase().split(" ");
  return arr.join("-");
}

const AuthorReciters = () => {
  const { i18n } = useTranslation();
  const data1 = useStaticQuery(graphql`
    query {
      allAuthorReciterJson {
        edges {
          node {
            title
            description
            featured
            image {
              id
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const allReciters = data1.allAuthorReciterJson.edges.filter(
    (item) => item.node.featured === true
  );

  console.log("Total featured reciters:", allReciters.length); // Add this log to see the number of featured reciters

  // Number of initially displayed images
  const initialDisplayCount = 25;

  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  // Ref for the last reciter element
  const lastReciterRef = useRef(null);

  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && displayCount < allReciters.length) {
        // Load the next 25 reciters when the last reciter is in view
        setDisplayCount((prevCount) => Math.min(prevCount + 25, allReciters.length));
      }
    });
  }, [displayCount, allReciters.length]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (lastReciterRef.current) {
      observer.observe(lastReciterRef.current);
    }

    // Cleanup the observer when component unmounts
    return () => observer.disconnect();
  }, [lastReciterRef, handleIntersection]);

  const displayedReciters = allReciters.slice(0, displayCount);

  console.log("Displayed reciters:", displayedReciters); // Add this log to see the number of displayed reciters
  
  return (
    <section className="audio-reciters-area">
      <div className="container">
        <h2>
          <Trans>reciters-header</Trans>
        </h2>
        <div className="flex flex-wrap justify-center">
          {displayedReciters.map((item, index) => {
            const image = getImage(item.node.image);
            const reciterTitle = item.node.title;

            return (
              <Link
                hrefLang={i18n.language}
                to={`/reciter/${connectString(reciterTitle)}/`}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-4 mb-4"
                key={index}
                ref={index === displayedReciters.length - 1 ? lastReciterRef : null}
              >
                <div className="author-reciter-photo">
                  <GatsbyImage
                    image={image}
                    alt={`Profile picture of ${reciterTitle}`}
                    style={{ borderRadius: "50%", width: "100%", height: "100%" }}
                  />
                </div>
                <div className="author-reciter-card">
                  <h2 className="card-title">
                    <Trans>{reciterTitle}</Trans>
                  </h2>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AuthorReciters;
