import * as React from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import HomeHero from "../components/HomeHero";
import { Helmet } from "react-helmet";
import AuthorReciters from "../components/AuthorReciters";
import Layout from "../components/Layout";
import PostLoop from "../components/PostLoop";
import { graphql } from "gatsby";
import FeaturedPostLoop from "../components/FeaturedPostLoop";
import Seo from "../components/Seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";
import Banner from "../templates/banner";
import RigthBanner from "../templates/rigthBanner";
const IndexPage = ({ data }) => {
  console.log("data->", data);
  const featuredPosts = data.allMarkdownRemark.nodes.filter(
    post => post.frontmatter.featured
  );
  const { t } = useTranslation();

  return (
    <Layout>
       <CookieConsent
  location="bottom"
  buttonText={t("Accept Cookies")}
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
  {t("This website uses cookies to enhance the user experience.")}{" "}
  <span style={{ fontSize: "10px" }}></span>
</CookieConsent>
<Helmet>
  {/* Add hrefLang attribute in the head */}
  <link rel="alternate" hrefLang="en" href="https://staging.quranplayermp3.com/" />
  <link rel="alternate" hrefLang="ar" href="https://staging.quranplayermp3.com/ar/" />
  <link rel="alternate" hrefLang="fr" href="https://staging.quranplayermp3.com/fr/" />
  <link rel="alternate" hrefLang="es" href="https://staging.quranplayermp3.com/es/" />
  <link rel="alternate" hrefLang="de" href="https://staging.quranplayermp3.com/de/" />
  <link rel="alternate" hrefLang="ur" href="https://staging.quranplayermp3.com/ur/" />
  <link rel="alternate" hrefLang="tr" href="https://staging.quranplayermp3.com/tr/" />
  <link rel="alternate" hrefLang="zh" href="https://staging.quranplayermp3.com/zh/" />
  <link rel="alternate" hrefLang="ru" href="https://staging.quranplayermp3.com/ru/" />
  <link rel="alternate" hrefLang="pt" href="https://staging.quranplayermp3.com/pt/" />
  <link rel="alternate" hrefLang="it" href="https://staging.quranplayermp3.com/it/" />
  <link rel="alternate" hrefLang="id" href="https://staging.quranplayermp3.com/id/" />
  <link rel="alternate" hrefLang="bn" href="https://staging.quranplayermp3.com/bn/" />
  <link rel="alternate" hrefLang="nl" href="https://staging.quranplayermp3.com/nl/" />
  <link rel="alternate" hrefLang="hi" href="https://staging.quranplayermp3.com/hi/" />
  <link rel="alternate" hrefLang="fa" href="https://staging.quranplayermp3.com/fa/" />
  <link rel="alternate" hrefLang="ug" href="https://staging.quranplayermp3.com/ug/" />
  <link rel="alternate" hrefLang="so" href="https://staging.quranplayermp3.com/so/" />
  <link rel="alternate" hrefLang="sw" href="https://staging.quranplayermp3.com/sw/" />
  <link rel="alternate" hrefLang="no" href="https://staging.quranplayermp3.com/no/" />
  <link rel="alternate" hrefLang="da" href="https://staging.quranplayermp3.com/da/" />
  <link rel="alternate" hrefLang="se" href="https://staging.quranplayermp3.com/se/" />
  <link rel="alternate" hrefLang="ha" href="https://staging.quranplayermp3.com/ha/" />
  <link rel="alternate" hrefLang="bs" href="https://staging.quranplayermp3.com/bs/" />
  <link rel="alternate" hrefLang="th" href="https://staging.quranplayermp3.com/th/" />
  <link rel="alternate" hrefLang="tg" href="https://staging.quranplayermp3.com/tg/" />
  <link rel="alternate" hrefLang="tl" href="https://staging.quranplayermp3.com/tl/" />
  <link rel="alternate" hrefLang="x-default" href="https://staging.quranplayermp3.com/" />
</Helmet>

      <Seo
        homePage={true}
        description={t("meta-description")}// Add your meta description here
      />
      
      <HomeHero />
      <AuthorReciters />
      <div className="main main-container">
        <div>
        {featuredPosts.length > 0 && (
          <FeaturedPostLoop
            posts={featuredPosts.map(post => ({
              ...post,
              frontmatter: {
                ...post.frontmatter,
                featuredImage: {
                  ...post.frontmatter.featuredImage,
                  image: getImage(post.frontmatter.featuredImage.image),
                },
              },
            }))}
            featuredPostCount={data.site.siteMetadata.featuredPostCount}
          />
        )}
        <PostLoop
          posts={data.allMarkdownRemark.nodes.map(post => ({
            ...post,
            frontmatter: {
              ...post.frontmatter,
              featuredImage: {
                ...post.frontmatter.featuredImage,
                image: getImage(post.frontmatter.featuredImage.image),
              },
            },
          }))}
          postPerPage={data.site.siteMetadata.postPerPage}
          sectionTitle={t("Latest post")}
        />
        </div>
        <RigthBanner/>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        featuredPostCount
        postPerPage
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      
      nodes {
        ...PostQueryFragment
      }
    }
  }
`;
